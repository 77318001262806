exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dfc-studies-js": () => import("./../../../src/pages/dfc-studies.js" /* webpackChunkName: "component---src-pages-dfc-studies-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-centers-js": () => import("./../../../src/pages/our-centers.js" /* webpackChunkName: "component---src-pages-our-centers-js" */),
  "component---src-pages-researchers-js": () => import("./../../../src/pages/researchers.js" /* webpackChunkName: "component---src-pages-researchers-js" */)
}

